import React, { useEffect, useRef, useState } from "react";
import styles from "./Navbar.module.css";
import logo from "../../shared/images/logo2.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../config";
import { useTranslation } from "react-i18next";
import i18n from "../../shared/i18n/i18n";
import flagEn from "../../shared/images/English.png";
import flagAr from "../../shared/images/sweden.png"; // Arabic flag

const Navbar = () => {
  const key = localStorage.getItem("Key");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const lan = localStorage.getItem("lang") || "swe";
  const [currentLanguage, setCurrentLanguage] = useState(lan);
  let newTranslationFunction = t;

  console.log(lan);
  useEffect(() => {
    i18n.changeLanguage("swe");
    setCurrentLanguage("swe");
    newTranslationFunction = i18n.getFixedT(lan);
  }, []);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const languages = {
    swe: {
      flag: flagAr,
      label: "Swedish",
    },
    en: {
      flag: flagEn,
      label: "English",
    },
  };
  const handleLanguageChange = (lang) => {
    // Implement logic to change the language using i18next or your chosen method
    setDropdownOpen(false);
    setCurrentLanguage(lang);
    if (lang === "en") {
      i18n.changeLanguage("en");
      localStorage.removeItem("lang");
      localStorage.setItem("lang", "en");
      newTranslationFunction = i18n.getFixedT(lan);
    } else {
      i18n.changeLanguage("swe");
      localStorage.removeItem("lang");
      localStorage.setItem("lang", "swe");
      newTranslationFunction = i18n.getFixedT(lan);
    }
  };

  // Logout The Website
  const logoutApi = async () => {
    const options = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const resp = await axios
        .get(`${baseURL}/signout/`, options)
        .then(function (response) {
          localStorage.removeItem("Key");
          toast("Logout SucessFully");
          localStorage.clear();
          navigate("/");
          window.location.reload();
          return response.data;
        });
    } catch (error) {
      console.log(error, "erorr");
      toast(error.response.data.detail);
    }
  };

  const navbarCollapseRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      navbarCollapseRef.current &&
      !navbarCollapseRef.current.contains(event.target)
    ) {
      const isNavbarExpanded = navbarCollapseRef.current.querySelector(
        ".navbar-collapse.show"
      );
      if (isNavbarExpanded) {
        const navbarToggle = document.querySelector(".navbar-toggler");
        if (navbarToggle) {
          navbarToggle.click(); // Trigger the collapse
        }
      }
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [navbarCollapseRef]);

  return (
    <nav
      className={`navbar navbar-expand-lg bg-light ${styles.navbarBg} shadow-sm`}
      ref={navbarCollapseRef}
    >
      <div className="container-fluid">
        <a className="navbar-brand ps-5" href="#">
          <img src={logo} className={styles.imgLogo} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className={`nav-link ${styles.navLinks}`} to={"/"}>
                {newTranslationFunction("Home")}
              </Link>
            </li>
            {/* {key && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${styles.navLinks}`}
                  to={"/my-rides"}
                >
                  {newTranslationFunction("Rides")}
                </Link>
              </li>
            )}
            {key && (
              <li className="nav-item">
                <Link className={`nav-link ${styles.navLinks}`} to={"/profile"}>
                  {newTranslationFunction("Profile")}
                </Link>
              </li>
            )} */}
            <li className="nav-item">
              <Link to={"/contact"} className={`nav-link ${styles.navLinks}`}>
                {newTranslationFunction("Contact")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${styles.navLinks}`} to={"/about"}>
                {newTranslationFunction("About")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${styles.navLinks}`}
                to={"/privacy-policy"}
              >
                {newTranslationFunction("Privacy Policy")}
              </Link>
            </li>

            {currentLanguage ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={languages[currentLanguage].flag}
                    alt={languages[currentLanguage].label}
                    width={20}
                    height={"auto"}
                  />
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {Object.keys(languages).map((lang) => (
                    <li key={lang} onClick={() => handleLanguageChange(lang)}>
                      <a className="dropdown-item" href="#">
                        <img
                          src={languages[lang].flag}
                          alt={languages[lang].label}
                          width={20}
                          height={"auto"}
                          className="me-2"
                        />
                        {languages[lang].label}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ) : null}

            <li className={`nav-item dropdown ${styles.marginSmBottom}`}>
              <a
                href="https://company.trixitaxi.com/"
                target="blank"
                className={`btn btn-sm btn-outline-primary ${styles.navButton}`}
              >
                {newTranslationFunction("Company Login")}
              </a>
            </li>

            {/* {key ? (
              <li className="nav-item dropdown">
                <button
                  onClick={() => logoutApi()}
                  className={`btn btn-sm btn-primary ${styles.navButton}`}
                >
                  {newTranslationFunction("Logout")}
                </button>
              </li>
            ) : (
              <>
                <li className={`nav-item dropdown ${styles.marginSmBottom}`}>
                  <Link
                    to={"/login"}
                    className={`btn btn-sm btn-primary ${styles.navButton}`}
                  >
                    {newTranslationFunction("Login")}
                  </Link>
                </li>
                <li className={`nav-item dropdown ${styles.marginSmBottom}`}>
                  <Link
                    to={"/register"}
                    className={`btn btn-sm btn-primary ${styles.navButton}`}
                  >
                    {newTranslationFunction("Register")}
                  </Link>
                </li>
              </>
            )} */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
