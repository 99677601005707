import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../config";
import axios from "axios";
import styles from './Contact.module.css'
import { FormControl, OutlinedInput } from "@mui/material";

const Contact = () => {
  const [message, setMessage] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [id, setid] = useState("");
  const nav = useNavigate();
  // contactUs Func
  const ContactUsFunc = async (e) => {
    e.preventDefault();
    console.log(email, message, name);
    if (email === "" || message === "" || name === "") {
      toast("All fields are mendetory");
      // setIsLoading(false)
      return;
    }
    const contactUsData = {
      name: name,
      email: email,
      message: message,
    };
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const resp = await axios
        .post(`${baseURL}/contact-us/`, contactUsData, options)
        .then(function (response) {
          toast("Response Submitted SucessFully");
          setemail("");
          setname("");
          setMessage("");
          nav("/contact");

          return response;
        });
    } catch (error) {
      toast("Something went wrong");
      nav("/contact");
    }
  };
  return (
    <div>
      <div className={`card ${styles.cardContainer} shadow`}>
        <div className="card-body">
        <h1 className={styles.heading}>Contact Us</h1>
        <div className="my-3">
            <form onSubmit={(e)=>ContactUsFunc(e)}>
            <div className="form-group mb-3">
                <label htmlFor="name">Name</label>
                    <input type="text" value={name} onChange={(e)=>setname(e.target.value)} id="name" className={`form-control ${styles.formText}`} />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="email">Email</label>
                    <input type="email" value={email} onChange={(e)=>setemail(e.target.value)} id="email" className={`form-control ${styles.formText}`} />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="msg">Message</label>
                    <textarea id="msg" value={message} onChange={(e)=>setMessage(e.target.value)} className={`form-control ${styles.formText}`}></textarea>
            </div>
            <div className="mt-5 d-flex justify-content-center">
                <button className="btn btn-primary btn-m">
                    Submit
                </button>
            </div>

            </form>

        
        </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
