import React from 'react'
import './Footer.css'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';


const Footer = () => {
  const { t, i18n } = useTranslation();
  const nav = useNavigate()
  return (
    <footer>
    <div className="text-center text-md-start p-5">
      <div className="footer-wrap">
        <div className="about">
          {/* <img src={Logo} alt=""/> */}
          <h4>Trixi Taxi</h4>
          <div className="social-media pt-3">
            <a href="" className="me-2 text-reset"><FacebookIcon /></a>
            <a href="" className="me-2 text-reset"><InstagramIcon /></a>
          </div>
          <p className=" py-4">
            <p className="mb-0">{t("Address")}: Stockholm, Sweden</p>
            <p>{t("Contact")}: +46760211685</p>
            <p>Info@trixitaxi.com</p>
          </p>
        </div>

        <div className="company">
          <h6 className="fw-bold">App</h6>
          <p><a href="https://play.google.com/store/apps/details?id=com.trixitaxi&pli=1" target="_blank">{t("Download App")}</a></p>
          <p><a href="#">FAQ</a></p>
        </div>
        <div className="company">
          <h6 className="fw-bold">User</h6>
          <p><a href="#" onClick={()=> nav('/signup')}>{t("Register")}</a></p>
          <p><a href="#" onClick={()=> nav('/signin')}>{t("Login")}</a></p>
          <p><a href="https://company.trixitaxi.com/" target="_blank">{t("Company Login")}</a></p>
          <p><a href="#"onClick={()=> nav('/about')}>{t("About Us")}</a></p>
          <p><a href="#" onClick={()=> nav('/privacypolicy')}>{t("Contact Us")}</a></p>
        </div>

        <div className="useful-links">
          <h6 className="fw-bold">{t("Useful Links")}</h6>
          <p><a href="#">{t("Terms of Services")}</a></p>
          <p><a href="#" onClick={()=> nav('/privacypolicy')}>{t("Privacy Policy")}</a></p>
        </div>
      </div>
    </div>

  </footer>
  )
}

export default Footer