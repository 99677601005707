import React from "react";
import styles from "./Privacy.module.css";

const Privacy = () => {
  return (
    <section className={styles.privacySection}>
      <h1 className={styles.privacyHeading}>Privacy Policy for Taxi Booking Service</h1>
      <p className={styles.description}>
        <div className='container p-3' style={{ backgroundColor: "#fff" }}>
          <h4 className={`h4 ${styles.h4} `}>I. General</h4>
          <p className={`${styles.subDescription}`}>
            This document informs you of our policies regarding the collection, use, and disclosure of your
            personal data when you use our Services and the choices you have associated with that data
          </p>


          <h4 className={`h4 ${styles.h4}`}>II. Personal Information We collect</h4>
          <p className={`${styles.subDescription}`}>
            When you use our taxi booking Service, we collect various types of information
            necessary to provide the service and improve user experience. This may include:
          </p>
          <ul>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>User Information: </strong>
              Information such as name, email address, phone number, and
              payment details provided by users during the registration process or when making a
              booking. We collect User information when users create or update their accounts, or
              place orders via Application. This includes first and last name, email, phone number,
              login name and password, address, profile picture, payment or banking information
              (But please note that we use third-party services for payment processing (e.g.
              payment processors). So we will not store or collect User’s payment card details.
              That information is provided directly to third-party payment processors (It is “Stripe
              Pay” as of the day the contract is signed) whose use of User’s personal information
              is governed by their Privacy Policy.
            </li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}> Location Information (Driver and User):</strong>
              GPS data and other information about
              your location when you use our app to request a trip or access certain features that
              require location data. We collect precise and approximate location data from drivers’
              and delivery persons’ mobile devices when our Application is running in the
              foreground (app open and on-screen) or background (app open but not on-screen).
              Users may use the App. without enabling collection of location data from their
              mobile devices. However, this may affect certain features in The App.. For example,
              a User who has not enabled precise location data will have to manually enter their
              pickup address. In addition, precise location data collected from a driver’s device
              during a trip is linked to the User’s account, even if the User has not enabled precise
              location data to be collected from their device. This data is used for purposes such
              as customer support, fraud detection, insurance and receipt generation.
            </li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Transaction information:</strong>
              We collect transaction information related to the use of
              our services, including the type of services requested or provided; trip or order
              details (such as date and time, requested pick-up and drop off addresses, distance
              traveled and payment transaction information .</li>

            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Driver Information:</strong>
              Information about drivers using our platform, including their
              name, contact information, vehicle details, and location data.
            </li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Device Information:</strong>
              Information about the device you use to access our Service,
              including device ID, hardware model, operating system, and mobile network
              information. We collect data about the devices used to access our services,
              including the hardware models, device IP address or other unique device identifiers,
              operating systems and versions, software, preferred languages, advertising
              identifiers, device motion data, and mobile network data.

            </li>

            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Communications data:</strong>
              We collect data regarding communications between users
              that are enabled through Apps. This includes communication type (phone, text or
              in-app message), date/time, and content (including recordings of phone calls solely
              when users are notified of the recording in advance)</li>


          </ul>

          <h5 className={`h5 ${styles.h4}`}>III. How We Use Your Personal Information</h5>
          <p className={`${styles.subDescription}`}>You can see the areas where we will use the information we have collected under the headings
            below;</p>

          <ul>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>To provide our services:</strong>
              We use data to provide, personalize, maintain, and improve our
              services such that:

              <ul>
                <li className={`${styles.subDescription}`}>create/update accounts.
                </li>
                <li className={`${styles.subDescription}`}>enable transportation, using location data to navigate driver pick-ups and
                  drop-offs, calculate ETAs (Expected Time of Arrival), and track the progress of
                  trips.

                </li>
                <li className={`${styles.subDescription}`}>enabling features that involve data sharing, such as sharing driver first name and
                  vehicle information with Users to facilitate pick-ups.
                </li>
                <li className={`${styles.subDescription}`}>matching available drivers to users requesting services, including based on
                  personal data such as location and proximity to other users, and user settings /
                  preferences (such as preferred destinations), and non-personal data such as
                  vehicle type requested.
                </li>
                <li className={`${styles.subDescription}`}>enabling accessibility features. (transportation provided by drivers has
                  transformed mobility for many people with disabilities).
                </li>
                <li className={`${styles.subDescription}`}> calculating prices and fares, including using location data and trip. We may also
                  consider non-personal data or factors, including date and time, estimated
                  distance and time, minimum base fares, tolls, taxes and fees.
                </li>
                <li className={`${styles.subDescription}`}>provide users with trip or delivery updates, generate receipts, and inform them
                  of changes to our terms, services, or policies.
                </li>
                <li className={`${styles.subDescription}`}>perform necessary operations to maintain our services, including to
                  troubleshoot software bugs and operational problems.
                </li>
              </ul>

              We perform the above activities on the grounds that they are necessary to fulfill the terms of
              our agreements with users, are compatible with such uses, or are necessary for purposes of
              Ours and Our users’ legitimate interests

            </li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Research and development:</strong>
              We use data for analysis, machine learning, product
              development, research, and testing. This helps us make our services more convenient
              and easy-to-use, enhance the safety and security of our services, and develop new
              services and features.

            </li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Enabling communications between users:</strong>
              For example, a driver may message or
              call a User to confirm a pick-up location, a User may call a driver to retrieve a lost
              item.
            </li>
            <li className={`${styles.subDescription}`}><strong className={`${styles.subHeading}`}>Marketing and Advertising: </strong>
              We use data to market our services.
            </li>
          </ul>

          <h4 className={`h4 ${styles.h4}`}>IV. Cookies & Third Party Technologies</h4>
          <p className={`${styles.subDescription}`}>We use cookies and other identification technologies in our online advertisements, our Website
            and our emails for the purposes stated below</p>
          <p className={`${styles.subDescription}`}>Cookies are small text files that are stored on browsers or devices by websites, apps, online
            media, and ads. We use cookies and similar technologies for purposes such as:</p>
          <ul>
            <li className={`${styles.subDescription}`}> authenticating users</li>

            <li className={`${styles.subDescription}`} >remembering user preferences and settings</li>
            <li className={`${styles.subDescription}`} >determining the popularity of content</li>
            <li className={`${styles.subDescription}`} >delivering and measuring the effectiveness of advertising campaigns</li>
            <li className={`${styles.subDescription}`} >analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with various services</li>

          </ul>
          <p className={`${styles.subDescription}`}>We may allow other companies to offer their products and services outside of our own use and
            to monitor the performance of the advertisements they offer.
          </p>
          <p className={`${styles.subDescription}`}>These entities may use cookies, web beacons, SDKs and other technologies to identify the
            devices used by visitors to our websites as well as when they visit other online sites and
            services.</p>


          <h4 className={`h4 ${styles.h4}`}>V. Data Sharing & Disclosure</h4>
          <p className={`${styles.subDescription}`}>We undertake that we will keep User or Driver information completely confidential and will not
            disclose it other than mandatory information to be used by the third party companies we work
            with. We also undertake that we will not share this information with anyone unless requested
            by the courts or legal authorities.
          </p>
          <p className={`${styles.subDescription}`}>However, we share the following data with Users, Drivers and Other Users:</p>
          <ul>
            <li className={`${styles.subDescription}`} > User’s first name, rating, and pickup and/or drooff locations with drivers.</li>
            <li className={`${styles.subDescription}`} > User’ first name with other Users in a carpool tri p. Users in carpool trips may also see the other Users’ drop off location.</li>
            <li className={`${styles.subDescription}`} > We may share data with the Users, including name and photo; vehicle make, model, color, license plate, and vehicle photo; location (before and during trip); average rating 3 provided by users; total number of trips; period of time since they signed up to be a driver or delivery person; contact information; and driver or delivery person profile, including compliments and other feedback submitted by past users.</li>
            <li className={`${styles.subDescription}`} > We also provide Users with receipts containing information such as a breakdown of amounts charged, driver first name, photo, and route map. We also include other information on those receipts if required by law.</li>

          </ul>

          <p className={`${styles.subDescription}`}>In case of the User's approval and consent, the following information may be shared:</p>
          <ul>
            <li className={`${styles.subDescription}`} >Other people at a user’s request. For example, we share a user’s ETA and location with a friend when requested by that user, or a user’s trip information when they split a fare with a friend.</li>
            <li className={`${styles.subDescription}`} >Emergency services. We offer features that enable users to share their data with police, fire, and ambulance services in the event of an emergency or after certain incidents.</li>
            <li className={`${styles.subDescription}`} >Insurance companies. If a user has reported or submits a claim to an insurance company relating to our services, we will share certain data with that insurance company for the purpose of adjusting or handling the user’s insurance claim.</li>
            <li className={`${styles.subDescription}`} >General public. Questions or comments from users submitted through public forums such as our blogs and our social media pages may be viewable by the public, including any data included in the questions or comments submitted by a user.</li>
          </ul>

          <p className={`${styles.subDescription}`}>
            At the same time, we compulsorily share the following information with our own service
            providers and business partners.</p>


          <p className={`${styles.subDescription}`}>These include the third parties, or categories of third parties, listed below. Where a third
            party is identified, please see their linked privacy notices for information regarding their
            collection and use of personal data.
            <ul>
              <li className={`${styles.subDescription}`} >payment processor,</li>
              <li className={`${styles.subDescription}`} >background check, identity verification providers,</li>
              <li className={`${styles.subDescription}`} >cloud storage provider.</li>
              <li className={`${styles.subDescription}`} >Google, in connection with the use of Google Maps in our App</li>
              <li className={`${styles.subDescription}`} >marketing partners and marketing platform providers, including social media advertising services, advertising networks, third-party data providers, and other service providers to reach or better understand our users and measure advertising effectiveness,</li>
              <li className={`${styles.subDescription}`} >service providers that assist us to enhance the safety and security of our Apps and services.</li>

            </ul>

          </p>
          <p className={`${styles.subDescription}`}>And finally, we may have to share information for legal reasons or in the event of dispute
            including</p>
          <p className={`${styles.subDescription}`}>We may share users’ data if we believe it’s required by applicable law, regulation, operating
            license or agreement, legal process or governmental request, insurance policy, or where the
            disclosure is otherwise appropriate due to safety or similar concerns.
          </p>
          <p className={`${styles.subDescription}`}>This includes sharing data with law enforcement officials, public health officials, other
            government authorities, or other third parties as necessary to enforce our Terms of Service,
            user agreements, or other policies; to protect our rights or property or the rights, safety, or
            property of others; or in the event of a claim or dispute relating to the use of our services. In
            the event of a dispute relating to use of another person’s credit card, we may be required by
            law to share a user’s data, including trip or order information, with the owner of that credit
            card</p>





          <h4 className={`h4 ${styles.h4}`}>VI. Data Retention and Deletion</h4>
          <p className={`${styles.subDescription}`}>Our company stores user and driver data for the periods required for the purposes stated in the above-mentioned sections of this privacy policy.</p>

          <p className={`${styles.subDescription}`}>Naturally, these periods vary depending on the type of data, purpose and necessity of collection.</p>

          <p className={`${styles.subDescription}`}>Although under normal circumstances, our users request the deletion of their data, sometimes we may have to reject our users' requests to delete their data when some data should not be deleted due to legal requirements.</p>

          <p className={`${styles.subDescription}`}>For 7 years if necessary to comply with tax requirements. For example, drivers' trip or delivery location information.</p>

          <p className={`${styles.subDescription}`}>for certain periods of time as necessary for security or fraud prevention purposes. From time to time, Users may request deletion of their accounts through our application or website.</p>

          <p className={`${styles.subDescription}`}>Upon receipt of such a request, we will delete the user's account and data, unless necessary for security, safety, fraud prevention or compliance with legal requirements or due to issues with the user's account (such as outstanding credit or unresolved debts).</p>

          <p className={`${styles.subDescription}`}>For drivers, this generally means that we retain some of their data for as long as required by law because it may be necessary for actual or potential tax, lawsuit or insurance claims.</p>

          <p className={`${styles.subDescription}`}>For travelers, we generally delete data within 90 days of the account deletion request, unless retention is necessary for the above reasons.</p>



          <h4 className={`h4 ${styles.h4}`}>VII. Our Transparency Policy</h4>
          <p className={`${styles.subDescription}`}>Our company allows our users to access the data collected from them and see what data is
            collected, both through our application and our website.</p>
          <p className={`${styles.subDescription}`}>Our users can view the data collected from them through the following</p>
          <ul>
            <li className={`${styles.subDescription}`} >Privacy settings (Users can set or update their preferences regarding location data collection and sharing, emergency data sharing, and notifications in Our Apps.)</li>
            <li className={`${styles.subDescription}`} >Device permissions (Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner’s permission, and these platforms have different methods for how that permission can be obtained. Users should check the available settings on their devices, or check with their provider)</li>
            <li className={`${styles.subDescription}`} >marketing and advertising options</li>
          </ul>

          <p className={`${styles.subDescription}`}>Our company also allows users to request access to or copies of their data, make changes or
            updates to their account, request deletion of their account, or request that our company restrict
            the processing of user data.
          </p>


          <h4 className={`h4 ${styles.h4}`}>VIII. Privacy Notice Updates</h4>
          <p className={`${styles.subDescription}`}>We may occasionally update this notice. If we make significant changes, we will notify users in
            advance of the changes through our Apps or through other means, such as email. We
            encourage users to periodically review this notice for the latest information on our privacy
            practices.</p>
          <p className={`${styles.subDescription}`}>Use of our services after an update constitutes consent to the updated notice to the extent
            permitted by law.</p>


          <div className="mt-5">
            <p className={`${styles.subDescription}`}>-----------------------------------------------------End of Document</p>
          </div>
        </div>
      </p>
    </section>
  );
};

export default Privacy;